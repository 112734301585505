import React from 'react';

const Chat = () => {
  const handleWhatsAppClick = () => {
    const phoneNumber = '+233547139631';
    const message = 'Hello!';
    const whatsappURL = `https://wa.me/${phoneNumber}?text=${encodeURIComponent(message)}`;
    window.open(whatsappURL, '_blank');
  };

  return (
    <button onClick={handleWhatsAppClick} style={styles.button}>
      <img src="https://upload.wikimedia.org/wikipedia/commons/thumb/6/6b/WhatsApp.svg/598px-WhatsApp.svg.png" alt="WhatsApp Icon" style={styles.icon} />
    </button>
  );
};

const styles = {
  button: {
    display: 'flex',
    alignItems: 'center',
    padding: '10px',
    border: 'none',
    borderRadius: '50%',
    backgroundColor: '#02011a1a',
    color: '#fff',
    cursor: 'pointer',
    fontSize: '16px',
    position: 'fixed',
    bottom: '20px', 
    right: '20px',
    width: '40px',
    transition: 'transform 0.3s ease', // Add transition for smooth transformation
  },


  icon: {
    width: '20px',
    height: '20px',
    marginRight: '10px',
  },
  label: {
    fontWeight: 'bold',
  },
};

export default Chat;
