import React, { useState } from 'react';
import axios from 'axios';
import { Link, useNavigate } from 'react-router-dom'; 
import registerimage from '../images/Car-rental-marketing.png';
import '../pages/Login.css';

const Login = () => {
  const [formData, setFormData] = useState({
    username: '',
    passw: ''
  });
  const [showSuccessMessage, setShowSuccessMessage] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const navigate = useNavigate();

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await axios.post('https://skrentalsimpleserver.vercel.app/login', formData);
      const { username } = response.data;
      setShowSuccessMessage(true);
      setTimeout(() => {
        setShowSuccessMessage(false);
        navigate('/');
      }, 3000);
    } catch (error) {
      console.error('Login failed:', error);
      if (error.response) {
        // Server responded with a status code outside of 2xx range
        if (error.response.status === 401) {
          setErrorMessage('Username or password incorrect');
        } else {
          setErrorMessage('An unexpected error occurred');
        }
      } if (error.request) {
        // The request was made but no response was received
        setErrorMessage('No response received from server');
      } else {
        // Something happened in setting up the request
        setErrorMessage('Error setting up the request');
      }
      setTimeout(() => setErrorMessage(''), 3000);
    }
  };

  return (
    <div className="form-container">
      <form className="form" onSubmit={handleSubmit}>
        <h2>Login</h2>
        <input
          type="text"
          placeholder="Username"
          name="username"
          value={formData.username}
          onChange={handleChange}
        />
        <input
          type="password"
          placeholder="Password"
          name="password"
          value={formData.password}
          onChange={handleChange}
        />
        <button type="submit">Login</button>
        <p>
          Don't have an account? <Link className='noreg' to="/sign-up">Register</Link>
        </p>
        {showSuccessMessage && 
          <div className='successauthstyle'>
            <p>Login successful</p>
          </div>
        }
        {errorMessage && 
          <div className='errorauthstyle'>
            <p>{errorMessage}</p>
          </div>
        }
      </form>
      <div className="image-container">
        <img loading='lazy' src={registerimage} alt="Login" />
      </div>
    </div>
  );
};

export default Login;
