import React, { useState } from 'react';
import axios from 'axios';
import '../pages/Register.css';
import registerimage from '../images/Car-rental-marketing.png';

const Register = () => {
  const [formData, setFormData] = useState({
    username: '',
    email: '',
    passw: '',
    confirmPassword: ''
  });
  const [showSuccessMessage, setShowSuccessMessage] = useState(false);
  const [showErrorMessage, setShowErrorMessage] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    // Check username length
    if (formData.username.length < 8) {
      setErrorMessage('Username must be at least 8 characters long.');
      setShowErrorMessage(true);
      setTimeout(() => setShowErrorMessage(false), 3000);
      return;
    }
    // Check password length
    if (formData.password.length < 8) {
      setErrorMessage('Password must be at least 8 characters long.');
      setShowErrorMessage(true);
      setTimeout(() => setShowErrorMessage(false), 3000);
      return;
    }
    // Check password and confirmPassword match
    if (formData.password !== formData.confirmPassword) {
      setErrorMessage('Passwords do not match.');
      setShowErrorMessage(true);
      setTimeout(() => setShowErrorMessage(false), 3000);
      return;
    }

    try {
      const response = await axios.post('https://skrentalsimpleserver.vercel.app/register/', formData);
      console.log('Registration successful:', response.data);
      setShowSuccessMessage(true);
      setTimeout(() => setShowSuccessMessage(false), 3000);
      resetForm();
    } catch (error) {
      console.error('Registration failed:', error);
      if (error.response) {
        // Server responded with a status code outside of 2xx range
        if (error.response.status === 400) {
          setErrorMessage(error.response.data.error);
        } else {
          setErrorMessage('An unexpected error occurred');
        }
      } else if (error.request) {
        // The request was made but no response was received
        setErrorMessage('No response received from server');
      } else {
        // Something happened in setting up the request
        setErrorMessage('Error setting up the request');
      }
      setShowErrorMessage(true);
      setTimeout(() => setShowErrorMessage(false), 3000);
    }
  };

  const resetForm = () => {
    setFormData({
      username: '',
      email: '',
      password: '',
      confirmPassword: ''
    });
  };

  return (
    <div className="form-container">
      <form className="registerform" onSubmit={handleSubmit}>
        <h2>Register</h2>
        <input
          type="text"
          placeholder="Username"
          name="username"
          value={formData.username}
          onChange={handleChange}
        />
        <input
          type="email"
          placeholder="Email"
          name="email"
          value={formData.email}
          onChange={handleChange}
        />
        <input
          type="password"
          placeholder="Password"
          name="password"
          value={formData.password}
          onChange={handleChange}
        />
        <input
          type="password"
          placeholder="Confirm Password"
          name="confirmPassword"
          value={formData.confirmPassword}
          onChange={handleChange}
        />
        <button type="submit">Register</button>
        {showSuccessMessage && (
          <div className="successauthstyle">
            <p>Registration successful</p>
          </div>
        )}
        {showErrorMessage && (
          <div className="errorauthstyle">
            <p>{errorMessage}</p>
          </div>
        )}
      </form>
      <div className="image-container">
        <img loading='lazy' src={registerimage} alt="Register" />
      </div>
    </div>
  );
};

export default Register;
