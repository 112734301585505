import React, { useState, useEffect } from 'react';
import '../components/Stats.css';
import { LineChart, PieChart, Pie, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts';

const Stats = () => {
  const [carsRented, setCarsRented] = useState({
    perDay: 0,
    perWeek: 0,
    perMonth: 0,
  });

  const [opacity, setOpacity] = useState({
    pv: 1,
    uv: 1,
  });

  useEffect(() => {
    const fetchRentalStats = async () => {
      try {
        const response = await fetch('https://skrentalsimpleserver.vercel.app/dashboard/stats');
        const data = await response.json();

        setCarsRented({
          perDay: data.rentalStats.perDay,
          perWeek: data.rentalStats.perWeek + 2,
          perMonth: data.rentalStats.perMonth + 4,
        });
      } catch (error) {
        console.error(error);
      }
    };

    fetchRentalStats();
  }, []);

  const currentDate = new Date().toLocaleString('en-us', { weekday: 'long' });
  const currentWeek = Math.ceil(new Date().getDate() / 7); // Calculate week within the month
  const currentMonth = new Date().toLocaleString('en-us', { month: 'long' });

  const dataLineChart = [
    { name: `${currentDate} (${carsRented.perDay})`, days: carsRented.perDay },
    { name: `Week ${currentWeek} (${carsRented.perWeek})`, days: carsRented.perWeek },
    { name: `${currentMonth} (${carsRented.perMonth})`, days: carsRented.perMonth },
  ];

  const dataPieChart = [
    { name: 'Day', value: carsRented.perDay },
    { name: 'Week', value: carsRented.perWeek },
    { name: 'Month', value: carsRented.perMonth },
  ];

  const handleMouseEnter = (o) => {
    const { dataKey } = o;
    setOpacity((prevOpacity) => ({ ...prevOpacity, [dataKey]: 0.5 }));
  };

  const handleMouseLeave = (o) => {
    const { dataKey } = o;
    setOpacity((prevOpacity) => ({ ...prevOpacity, [dataKey]: 1 }));
  };

  return (
    <div className="stats-container">
      <h2>Rental Statistics</h2>

      <div className="stats-box">
        {/* <div className="stats-item">
          <h3>Number of Cars</h3>
          <p>Day: {carsRented.perDay}</p>
          <p>Week: {carsRented.perWeek}</p>
          <p>Month: {carsRented.perMonth}</p>
        </div> */}

        <ResponsiveContainer width="100%" height={300}>
          <LineChart
            width={500}
            height={300}
            data={dataLineChart}
            margin={{
              top: 5,
              right: 30,
              left: 20,
              bottom: 5,
            }}
          >
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis dataKey="name" />
            <YAxis />
            <Tooltip />
            <Legend onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave} />
            <Line type="monotone" dataKey="days" strokeOpacity={opacity.pv} stroke="#8884d8" activeDot={{ r: 8 }} />
          </LineChart>
        </ResponsiveContainer>

        <ResponsiveContainer className="stub" width="100%" height={300}>
          <PieChart>
            <Pie dataKey="value" data={dataPieChart} fill="#465760" label />
            <Tooltip />
          </PieChart>
        </ResponsiveContainer>
      </div>
      <hr />
    </div>
  );
};

export default Stats;
