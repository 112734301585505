import React, { useState } from 'react';
import './Reviews.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faStar } from '@fortawesome/free-solid-svg-icons'; 

const Reviews = () => {
  // State for the review ratings
  const [cleanlinessRating, setCleanlinessRating] = useState(0);
  const [performanceRating, setPerformanceRating] = useState(0);
  const [comfortRating, setComfortRating] = useState(0);
  const [safetyRating, setSafetyRating] = useState(0);
  const [featuresRating, setFeaturesRating] = useState(0);

  // Calculate the average rating
  const averageRating = (cleanlinessRating + performanceRating + comfortRating + safetyRating + featuresRating) / 5;

  return (
    <div className="reviews-container">
      <div className="reviews-title">
        <FontAwesomeIcon icon={faStar} className="star-icon" /> Review
      </div>
      <div className="ratings-section">
        <div className="rating-item">
          <p>Cleanliness / Appearance</p>
          <input type="range" min="0" max="5" value={cleanlinessRating} onChange={(e) => setCleanlinessRating(Number(e.target.value))} />
          <span>{cleanlinessRating}</span>
        </div>
        <div className="rating-item">
          <p>Performance / Handling</p>
          <input type="range" min="0" max="5" value={performanceRating} onChange={(e) => setPerformanceRating(Number(e.target.value))} />
          <span>{performanceRating}</span>
        </div>
        <div className="rating-item">
          <p>Comfort</p>
          <input type="range" min="0" max="5" value={comfortRating} onChange={(e) => setComfortRating(Number(e.target.value))} />
          <span>{comfortRating}</span>
        </div>
        <div className="rating-item">
          <p>Safety</p>
          <input type="range" min="0" max="5" value={safetyRating} onChange={(e) => setSafetyRating(Number(e.target.value))} />
          <span>{safetyRating}</span>
        </div>
        <div className="rating-item">
          <p>Features</p>
          <input type="range" min="0" max="5" value={featuresRating} onChange={(e) => setFeaturesRating(Number(e.target.value))} />
          <span>{featuresRating}</span>
        </div>
      </div>
      <div className="average-rating">
        <p>Average Rating: {averageRating.toFixed(1)}/5</p>
        <p>{averageRating === 0 ? "Not Rated" : `Based on ${cleanlinessRating + performanceRating + comfortRating + safetyRating + featuresRating} reviews`}</p>
      </div>
    </div>
  );
};

export default Reviews;
