import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUser, faCar, faCog, faWind } from '@fortawesome/free-solid-svg-icons';

const MagIcons = () => {
  const icons = [
    { name: '5 Seater', icon: faUser },
    { name: 'Automatic', icon: faCog },
    { name: 'Baggage', icon: faCar },
    { name: 'AC', icon: faWind },
  ];

  return (
    <div className="outer-container">
      {icons.map((icon, index) => (
        <div key={index} className="margin: magicon-wrapper">
          <div className="magicon" title={icon.name}>
            <FontAwesomeIcon icon={icon.icon} />
          </div>
        </div>
      ))}
    </div>
  );
};


/* 
const cars = [
    {
        id: 1,
        image: 'https://res.cloudinary.com/dy5hgr3av/image/upload/v1631093707/dakudarentals/mercedes-benz-cls-2018- 4k-5k-8k-10k-2018-cars-4k-5k-8k-10k-  wallpapers-hd-wallpapers-4k-5k-8k-10k-  2018-cars-wallpapers-768x432.jpg',
        label: 'Mercedes Benz',
        rentPrice: 300,
        description: 'The Mercedes-Benz CLS-Class is a series of executive cars produced by Mercedes-Benz since 2004. The original model was a four-door fastback based on the Mercedes E-Class platform, marketed as a four door coupé. An estate (Shooting Brake) model was later added to the model range with the second generation CLS.',
        location: 'Accra',
        pickupTime: '10:00am',
        dropoffTime: '10:00am',
    },
]; */
    
    

export default MagIcons;
