import React, { useState } from 'react';
import './FAQ.css';
import homeImage from '../images/Car-rental-marketing.png';
import Navbar from '../components/Navbar';

const FAQ = () => {
  const [activeIndex, setActiveIndex] = useState(null);

  // Arrays of questions and answers
  const questions = [
    'How can I make a reservation for a car rental?',
    'What types of vehicles do you offer for rent?',
    'What are your rental rates and pricing options?',
    'Do you provide insurance coverage for rented vehicles?',
    'What is your policy on fuel charges and refueling?',
    'Is there an age requirement for renting a car?',
    'What are your policies regarding additional drivers?',
    'Do you offer any discounts or promotions for car rentals?',
  ];

  const answers = [
    'You can make a reservation for a car rental through our website, mobile app, or by contacting our customer service.',
    'We offer a wide range of vehicles for rent including sedans, SUVs, trucks, and vans to suit your needs.',
    'Our rental rates vary depending on the vehicle type, rental duration, and any additional services or options you select. Please visit our website or contact us for detailed pricing information.',
    'Yes, we provide insurance coverage options for rented vehicles. Our insurance packages offer varying levels of coverage to meet your needs and preferences.',
    'Our policy on fuel charges typically requires renters to return the vehicle with the same level of fuel as when it was rented. Alternatively, we offer fuel pre-purchase options for added convenience.',
    'Yes, there is typically an age requirement for renting a car. The minimum age to rent a car may vary depending on location and vehicle type. Please check our terms and conditions for specific age requirements.',
    'We have policies in place regarding additional drivers. Additional drivers may be added to the rental agreement for an extra fee, subject to meeting certain criteria such as age and valid driver\'s license.',
    'Yes, we regularly offer discounts and promotions for car rentals. These may include special rates for loyalty members, promotional codes, or seasonal offers. Be sure to check our website or subscribe to our newsletter for the latest deals.',
  ];


  const handleQuestionClick = (index) => {
    setActiveIndex(activeIndex === index ? null : index);
  };

  return (
    <div className="faq">
       <Navbar />
      <div className="faq-home">
        <div className="faq-overlay"><h2>Frequently Asked Questions</h2></div>
      </div>
      <div className="faq-container">
        <div className="faq-list">
          {questions.map((question, index) => (
            <div key={index} className="faq-item">
              <div className="question" onClick={() => handleQuestionClick(index)}>
                <div>{question}</div>
                <div className="icon">{activeIndex === index ? '-' : '+'}</div>
              </div>
              {activeIndex === index && (
                <div className="answer">
                  {answers[index]}
                </div>
              )}
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default FAQ;
