import React from 'react';
import CarsForRent from '../components/CarForRent'; 



const HomeSection = () => {


  return (
    <div className="home-section-container">
      <CarsForRent/>
    </div>
  );
};

export default HomeSection;
