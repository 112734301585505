import React, { useState, useEffect } from 'react';
import '../components/Ads.css';
import axios from 'axios';

function Ads({ setFilter }) {
  const [popularBrands, setPopularBrands] = useState([]);
  const [selectedBrands, setSelectedBrands] = useState([]);

  useEffect(() => {
    const fetchPopularBrands = async () => {
      try {
        const response = await axios.get('https://skrentalsimpleserver.vercel.app/brand');
        setPopularBrands(response.data.brands);
      } catch (error) {
        console.error('Error fetching popular brands:', error);
      }
    };

    fetchPopularBrands();
  }, []);

  const handleCheckboxChange = (event) => {
    const { value, checked } = event.target;
    setSelectedBrands((prevSelectedBrands) => {
      if (checked) {
        return [...prevSelectedBrands, value];
      } else {
        return prevSelectedBrands.filter((brand) => brand !== value);
      }
    });
  };
  
  useEffect(() => {
    // Call setFilter function whenever selectedBrands state changes
    setFilter(selectedBrands);
  }, [selectedBrands]);
  

  return (
    <div>
      <div className="ads-container">
        <div className="popular-content">
          <h3>Popular brands</h3>
          <ul>
            {popularBrands.map((brand) => (
              <li className='branddiv' key={brand}>
                <input
                  className="checkbox-input"
                  type="checkbox"
                  value={brand}
                  onChange={handleCheckboxChange}
                />
                <label className="checkbox-label">{brand}</label>
              </li>
            ))}
          </ul>
        </div>

        <div className="popular-content">
          <h3>Popular Locations</h3>
          <ul>
            <li>Accra</li>
            <li>Kumasi</li>
            <li>Takoradi</li>
            <li>Ho</li>
          </ul>
        </div>
      </div>
    </div>
  );
}

export default Ads;
