import React, { useEffect, useState, useCallback } from 'react';
import '../components/Advert.css';

const Advert = React.memo(() => {
  const [isVisible, setIsVisible] = useState(false);
  const [position, setPosition] = useState('');

  useEffect(() => {
    // Randomly determine if the ad should be displayed
    const shouldShowAd = Math.random() < 0.5; // 50% chance to show the ad
    if (shouldShowAd) {
      setIsVisible(true);
      // Randomly determine the position (left or right)
      setPosition(Math.random() < 0.5 ? 'left' : 'right');
    }
  }, []);

  const handleClose = useCallback(() => {
    setIsVisible(false);
  }, []);

  if (!isVisible) return null;

  return (
    <div className="ad-overlay">
      <div className={`ad-popup ${position}`}>
        <p className="close-btn" onClick={handleClose}>×</p>
      </div>
    </div>
  );
});

export default Advert;
