import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import backgroundImage from '../images/lightreno.jpg';

const SearchBarWrapper = styled.div`
  width: 100%;
  background-image: url(${backgroundImage});
  filter: grayscale(100%);
  background-size: cover;
  background-repeat: no-repeat;
  height: 24rem;
  display: flex;
  justify-content: center;
  align-items: center;

  transition: transform 0.5s ease;

  &.hide-search-bar {
    transform: translateY(-5%);
  }

  &::after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5); /* Adjust the opacity (last value) as needed */
  }
`;

const SearchInput = styled.input`
  width: 100%;
  padding: 12px;
  border-radius: 26px;
  border: 3px solid #ccc;
  transition: width 0.5s ease;
  z-index: 999;
`;

const SearchBar = ({ searchInput, setSearchInput, priceFilter, setPriceFilter }) => {
  const [isExpanded, setExpanded] = useState(false);
  const [hideSearchBar, setHideSearchBar] = useState(false);

  const handleFocus = () => {
    setExpanded(true);
  };

  const handleBlur = () => {
    setExpanded(false);
  };

  useEffect(() => {
    const handleScroll = () => {
      const currentScrollY = window.scrollY;
      if (currentScrollY > 0) {
        setHideSearchBar(true);
      } else {
        setHideSearchBar(false);
      }
    };

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  return (
    <SearchBarWrapper className={hideSearchBar ? 'hide-search-bar' : ''}>
      <SearchInput
        type="text"
        placeholder="Search by model, location or price..."
        value={searchInput}
        onChange={(e) => setSearchInput(e.target.value)}
        onFocus={handleFocus}
        onBlur={handleBlur}
        style={{ width: isExpanded ? '330px' : '210px' }}
      />
    </SearchBarWrapper>
  );
};

export default SearchBar;
