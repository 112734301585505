import React, { useState, useEffect } from 'react';
import axios from 'axios';
import CarList from '../components/Fullinfo';
import './Dashboard.css';
import Stats from '../components/RentStats';
import Navbar from '../components/Navbar';

const Dashboard = () => {
  const [cars, setCars] = useState([]);
  const [newCar, setNewCar] = useState({
    images: null,
    brand: '',
    label: '',
    pickupDate: null,
    dropoffDate: null,
    description: '',
    rentPrice: 0,
    location: '',
  });
  const [showModal, setShowModal] = useState(false);
  const [showdeleteModal, setShowdeleteModal] = useState(false);
  const [file1, setFile1] = useState();
  const [file2, setFile2] = useState();
  const imageFiles = [file1, file2];

  useEffect(() => {
    const fetchCarsForDashboard = async () => {
      try {
        const response = await axios.get('https://skrentalsimpleserver.vercel.app/dashboard/');
        setCars(response.data);
      } catch (error) {
        console.error(error);
      }
    };

    fetchCarsForDashboard();
  }, []);


  const handleDelete = async (id) => {
    console.log('deleting car start', id);
    try {
      await axios.delete(`https://skrentalsimpleserver.vercel.app/delete/${id}`);
      setCars((prevCars) => prevCars.filter((car) => car._id !== id));
      // console.log('deleting car end', id);
      setShowdeleteModal(true);
      // Hide the message after 2 seconds
      setTimeout(() => {  
        window.location.href = '/dashboard';
      }, 1000);
    } catch (error) {
      console.error(error);
    }
  };

  const handleImageUpload1 = (e) => {
    const file1 = e.target.files[0];
    setFile1(file1);
  };

  const handleImageUpload2 = (e) => {
    const file2 = e.target.files[0];
    setFile2(file2);
  };

  const handleAddCar = async () => {
    try {
      // Cloudinary upload URL
      let cloudname = process.env.REACT_APP_CLOUDINARY_CLOUD_NAME;
      let api_key = process.env.REACT_APP_CLOUDINARY_API_KEY;
      let api_secret = process.env.REACT_APP_CLOUDINARY_API_SECRET;
      let upload_preset = process.env.REACT_APP_CLOUDINARY_UPLOAD_PRESET;
      let resourcetype = 'image';
      let folderName = 'rentalappimages';
      let api = `https://api.cloudinary.com/v1_1/${cloudname}/${resourcetype}/upload?api_key=${api_key}&api_secret=${api_secret}&upload_preset=${upload_preset}`;
  
      // Upload images to Cloudinary
      const imageUploadPromises = imageFiles.map((file) => {
        const data = new FormData();
        data.append('file', file);
        data.append('folder', folderName);
        return axios.post(api, data, {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        });
      });
  
      // Wait for all image uploads to complete
      const imageResponses = await Promise.all(imageUploadPromises);
  
      // Extract public URLs from Cloudinary responses
      const imageUrls = imageResponses.map((response) => response.data.secure_url);
      // console.log('imageUrls:', imageUrls);
  
      // Send image URLs to the server
      const formData = {
        images: imageUrls,
        brand: newCar.brand,
        label: newCar.label,
        pickupDate: newCar.pickupDate,
        dropoffDate: newCar.dropoffDate,
        description: newCar.description,
        rentPrice: newCar.rentPrice,
        location: newCar.location,
      };
  
      // console.log('main general formdata:', formData);
  
      // Send combined data to the server
      const response = await axios.post('https://skrentalsimpleserver.vercel.app/create-car/', formData);
  
      // Handle response from the server
      if (response.status === 201) {
        const responseData = response.data;
        setCars((prevCars) => [...prevCars, responseData]);
        setNewCar({
          images: null,
          brand: '',
          label: '',
          pickupDate: null,
          dropoffDate: null,
          description: '',
          rentPrice: '',
          location: '',
          paidStatus: false,
        });
        setShowModal(true);
        setTimeout(() => {
          setShowModal(false);
        }, 1000);

      } else {
        console.error(`Failed to add car. Status: ${response.status}`);
      }
    } catch (error) {
      if (error.name === 'TypeError') {
        console.error('Network error or CORS issue');
      } else {
        console.error('Unexpected error:', error.message);
      }
    }
  };
  

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setNewCar((prevCar) => ({ ...prevCar, [name]: value }));
  };


  const carBrands = ["Mercedez","Toyota","BMW","Audi","Honda","Ford","Nissan","Volkswagen","Hyundai","Kia","Mitsubishi","Subaru","Chevrolet","Peugeot","Land Rover","Jeep","Porsche","Lexus","Dodge","Jaguar","Volvo","Chrysler","Mini","Mazda","Suzuki"];
  
  

  return (
    <section className="dashboardmain">
      <Navbar />
      <Stats />
      <div className="dashbody">
        <h2>Dashboard</h2>
        <div className="innerform">
          <h3>Add New Car</h3>
          <form>
            <div>
              <input type="file" accept="image/*" onChange={handleImageUpload1} />
            </div>

            <div>
              <input type="file" accept="image/*" onChange={handleImageUpload2} />
            </div>

            <select id="location" name="brand" value={newCar.brand} onChange={handleInputChange}>
            <option value="">Brands</option>
            {carBrands.map(brand => (
              <option key={brand} value={brand}>{brand}</option>
            ))}
            </select><br />

            <input
              type="text"
              name="label"
              value={newCar.label}
              onChange={handleInputChange}
              placeholder="Car Name"
            />

            <textarea
              type="text"
              name="description"
              value={newCar.description}
              onChange={handleInputChange}
              rows="4"
              cols="60"
              style={{ resize: 'none' }}
              placeholder="Description"
            />

            <input
              className="prce"
              type="number"
              name="rentPrice"
              value={newCar.rentPrice}
              onChange={handleInputChange}
              placeholder="Price"
            />
            <br />

            <select id="location" name="location" value={newCar.location} onChange={handleInputChange}>
              <option value="">Location</option>
              <option value="Accra">Accra</option>
              <option value="Tema">Tema</option>
              <option value="Spintex">Spintex</option>
            </select>
            <br />
            {showModal ? (
        <div className="smodal">
          <p>Car submitted successfully!</p>
        </div>
      ) : null}

      {showdeleteModal ? (
        <div className="dmodal">
          <p>successfully deleted!</p>
        </div>
      ) : null}
            <button className="btn41-43 btn-42" type="button" onClick={handleAddCar}>
              Post Car
            </button>
          </form>
          
        </div>
      </div>

      <CarList cars={cars} handleDelete={handleDelete} />
    </section>
  );
};

export default Dashboard;
