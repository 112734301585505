// CarsForRent.js
//http://localhost:5000
import React, { useState, useEffect, useCallback } from 'react';
import spinnergif from '../images/ZWdx.gif';
import axios from 'axios';
import StripeCheckout from 'react-stripe-checkout';
import Icons from './Icons';
import MagIcons from './MagIcons';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import Modal from 'react-modal';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import SearchBar from '../components/searchBar';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPhone } from '@fortawesome/free-solid-svg-icons';
import Navbar from './Navbar';
import Ads from './Ads'
import Footer from './Footer';
import WhatsAppButton from "./Chat";
import Reviews from './Reviews';
import Advert from './Advert';


const CarsForRent = () => {
    const [selectedBrands, setSelectedBrands] = useState([]);
    const [numberofcars, setCarsRented] = useState({perDay: 0});
    const [displayedCars, setdisplayedCars] = useState([]);
    const [searchInput, setSearchInput] = useState('');
    const [cars, setCars] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [pickupDate, setPickupDate] = useState(null);
    const [pickupTime, setPickupTime] = useState(null);
    const [dropoffDate, setDropoffDate] = useState(null);
    const [dropoffTime, setDropoffTime] = useState(null);
    const [selectedCar, setSelectedCar] = useState(null);
    const [modalIsOpen, setModalIsOpen] = useState(false);
    const [moreDetailsModalIsOpen, setMoreDetailsModalIsOpen] = useState(false);
    const [userData, setUserData] = useState({
      name: '',
      phoneNumber: '',
      pickupTime: '',
      dropoffTime: '',
    });
    const [paymentStatus, setPaymentStatus] = useState('');
    const [priceFilter, setPriceFilter] = useState('');



  useEffect(() => {
    const fetchCarsForRent = async () => {
      try {
        const response = await axios.get('https://skrentalsimpleserver.vercel.app/');
        const filteredCars = response.data.filter((car) => !car.paidStatus);
        // console.log('filteredCars:', filteredCars);
        setCars(filteredCars);
      } catch (error) {
        console.error(error);
        setError('Error fetching cars. Please try again.');
      } finally {
        setTimeout(() => {
          setLoading(false);
        }, 1000);
      }
    };

    fetchCarsForRent();
  }, []);


  useEffect(() => {
    // Filter cars based on selected brands
    const displayedCars = cars.filter((car) =>
      selectedBrands.length === 0 ? true : selectedBrands.includes(car.brand)
    );
    setdisplayedCars(displayedCars);
  }, [selectedBrands, cars]);



  useEffect(() => {
    // Assume you have an API endpoint to fetch the rental statistics
    const fetchRentalStats = async () => {
      try {
        // Make a fetch request or use axios to get rental statistics
        // Example API endpoint: '/api/rental-stats'
        const response = await fetch('https://skrentalsimpleserver.vercel.app/dashboard/stats');
        const data = await response.json();
        // console.log('data after fetch:', data);

        // Update the state with the fetched rental statistics
        setCarsRented({
          perDay: data.rentalStats.perDay,
        });
      } catch (error) {
        console.error(error);
      }
    };

    // Fetch rental statistics on component mount
    fetchRentalStats();
  }, []);



  // Function to update search input based on selected brands
  const updateSearchInput = (selectedBrands) => {
    // Concatenate selected brands with '|' for regex OR condition
    const searchString = selectedBrands.join('|');
    setSearchInput(searchString);
  };


  const handleMoreDetailsClick = useCallback((car) => {
    setSelectedCar(car);
    setMoreDetailsModalIsOpen(true);
  },[]);

  const closeMoreDetailsbutton = () => {
    setMoreDetailsModalIsOpen(false);
  };

  const handleModalClose = () => {
    setModalIsOpen(false);
    setMoreDetailsModalIsOpen(false);
    setSelectedCar(null);
    setUserData({
      name: '',
      phoneNumber: '',
      pickupTime: '',
      dropoffTime: '',
    });
    setPaymentStatus('');
  };

  const handleNextClick = () => {
    if (!userData.name || !userData.phoneNumber || !pickupDate || !pickupTime || !dropoffDate || !dropoffTime) {
      alert('Please fill in all required fields');
      return;
    }
    setPaymentStatus('pending');
  };

  const handlepaydeets = () => {
    setModalIsOpen(true);
    setMoreDetailsModalIsOpen(false);
  };

  const handlePaymentSuccess = () => {
    const updatedCar = {
      ...selectedCar,
      clientName: userData.name,
      clientPhoneNumber: userData.phoneNumber,
      pickupDate: pickupDate,
      pickupTime: userData.pickupTime,
      dropoffDate: dropoffDate,
      dropoffTime: userData.dropoffTime,
      paidStatus: 'paid',
    };
    axios
      .put(`https://skrentalsimpleserver.vercel.app/${selectedCar._id}`, updatedCar)
      .then((response) => {
        if (response.status === 200) {
          console.log('Car data updated successfully:', response.data);
        }
      })
      .catch((error) => {
        console.error('Error updating car data:', error);
      });

    handleModalClose();
  };

  if (loading) {
    return (
      <div className='spinnergffff'>
        <div className="banter-loader">
            <div className="banter-loader__box"></div>
            <div className="banter-loader__box"></div>
            <div className="banter-loader__box"></div>
            <div className="banter-loader__box"></div>
            <div className="banter-loader__box"></div>
            <div className="banter-loader__box"></div>
            <div className="banter-loader__box"></div>
            <div className="banter-loader__box"></div>
            <div className="banter-loader__box"></div>
        </div>
      </div>
    );
  }

   if (error) {
    return <div className='clipz'><h1 className='friendly-error'>404 not found, try again...</h1></div>; // Display a user-friendly error message
  } 

  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
  };

//if no car matches the search input, display a message

  const filteredCars = cars.filter((car) => {
    const searchRegex = new RegExp(searchInput, 'i');
    const priceCondition = !priceFilter || car.rentPrice <= priceFilter;
    

    return (
      priceCondition &&
      (searchRegex.test(car.label) ||
       searchRegex.test(car.brand) ||
       searchRegex.test(car.description) || 
       searchRegex.test(car.location) || 
       searchRegex.test(car.rentPrice.toString()))
    ) 
  });

  return (
    <div className="main-cars-for-rent-container">
      <Navbar />
      <SearchBar setSearchInput={setSearchInput} />
      <div className="cars-for-rent-container">
  
        <div className='populs'>
        <Ads setFilter={updateSearchInput} />
        </div>{/* end of popul */}
  
        <div className='cardlist'>
        <p style={{ color: 'black' }} className='intstats'><strong>{numberofcars.perDay}</strong> Cars Available</p>
          <div className="car-header">
            <h1>Cars Available for Rent</h1>
          </div>
  
          {filteredCars.length === 0 ? (
            <p className='car-not-found-msg'>No cars found matching your search parameters</p>
          ) : null}
  
            {filteredCars.slice().reverse().map((car) => (
            <div key={car._id} className="car-card">
              <div className='upper'>
                <div className='img1'>
                  <img onClick={() => handleMoreDetailsClick(car)} src={car.images[0]} alt={car.label} loading='lazy' />
                </div>
              </div>
              
              <div className='lower'>
                <Icons />
                <p>{car.brand}</p>
                <h3 onClick={() => handleMoreDetailsClick(car)}>{car.label}</h3>
                <p>Rent Price: GHS{car.rentPrice}/ per day</p>
              </div>
  
              <div className='last'>
                <button onClick={() => handleMoreDetailsClick(car)}>More Details</button>
              </div>
            </div>
          ))}
  
        </div>
  
        <Advert />
        <WhatsAppButton />

      <Modal
        isOpen={modalIsOpen}
        onRequestClose={handleModalClose}
        contentLabel="Rent Modal"
        className="modal"
        overlayClassName="overlay"
      >
        {paymentStatus === 'paid' ? (
          <div>
            <h2>Successfully Paid!</h2>
            <button onClick={handleModalClose}>Close</button>
          </div>
        ) : (
          <div>
            <h2>Rent Details</h2>
            <form>
              <input
                type="text"
                name="name"
                placeholder='Name'
                id='modal-input'
                value={userData.name}
                onChange={(e) => setUserData({ ...userData, name: e.target.value })}
              />

              <input
                type="text"
                name="phoneNumber"
                id='modal-input'
                placeholder='Phone Number'
                value={userData.phoneNumber}
                onChange={(e) => setUserData({ ...userData, phoneNumber: e.target.value })}
              />

              <DatePicker
                id='modal-input'
                selected={pickupDate}
                placeholderText='Pickup Date'
                onChange={(date) => setPickupDate(date)}
              />

              <input
                type="time"
                id='modal-input'
                value={pickupTime}
                onChange={(e) => setUserData({ ...userData, pickupTime: e.target.value })}
              />

              <DatePicker
                id='modal-input'
                selected={dropoffDate}
                placeholderText='Dropoff Date'
                onChange={(date) => setDropoffDate(date)}
              />

              <input
                type="time"
                id='modal-input'
                value={dropoffTime}
                onChange={(e) => setUserData({ ...userData, dropoffTime: e.target.value })}
              />
            </form>
            {paymentStatus === 'pending' ? (
              <div>
                <button onClick={handleNextClick}>Next</button>
              </div>
            ) : (
              <StripeCheckout
                token={handlePaymentSuccess}
                stripeKey="your_stripe_publishable_key"
                amount={selectedCar?.rentPrice ? selectedCar.rentPrice * 100 : 0} // Amount in cents
                name="Car Rental Payment"
              />
            )}
          </div>
        )}
      </Modal>

      <Modal
        isOpen={moreDetailsModalIsOpen}
        onRequestClose={() => setMoreDetailsModalIsOpen(false)}
        contentLabel="More Details Modal"
        className="modal-more-details"
        overlayClassName="overlay-more-details"
        id="more-details-modal"
      >
        {selectedCar && (
          <div>
            <div className='close-mag'>
              <button className='btn41-43 ' onClick={closeMoreDetailsbutton}>Close</button>
            </div>
            <h2>{selectedCar.label}</h2>
            <Slider {...settings}>
              <div className='sld'>
                <img src={selectedCar.images[0]} alt={selectedCar.label} className="modal-image" />
              </div>
              <div className='sld'>
                <img src={selectedCar.images[1]} alt={selectedCar.label} className="modal-image" />
              </div>
            </Slider>
            <br /><hr /><hr />
            <MagIcons />
            <div className='car-shift-mag'>
              <h1>About this car</h1>
              <h3>{selectedCar.label}</h3>
              <p>{selectedCar.description}</p>
              <p><strong>Location:</strong> {selectedCar.location}</p>
              <p><strong>Rent Price:</strong> GHS{selectedCar.rentPrice}/ per day</p><br />
              {/* <p><strong>PickupDate:</strong> {selectedCar.pickupDate}</p>
              <p><strong>Pickup Time:</strong> {selectedCar.pickupTime}</p>
              <p><strong>Dropoff Date:</strong> {selectedCar.dropoffDate}</p>
              <p><strong>Dropoff Time</strong>: {selectedCar.dropoffTime}</p> */}
              <div className='botin'>
              <button className='btn41-43 btn-42' onClick={() => handlepaydeets()}>Pay</button>  or   
              <button className='btn41-43 btn-42' onClick={() => window.location.href = 'tel:+233547139631'}> 
              <FontAwesomeIcon icon={faPhone} /> Call
              </button>
              </div>

              <br /> <hr />

              <div className="terms-container">
                <div className="dos-and-donts">
                  <h2>✅ DOS & ❌ DON’TS</h2>
                  <ul>
                    <li>NO SMOKING of cigarettes or marijuana is allowed in this car</li>
                    <li>NO DRINKING & driving under the influence</li>
                    <li>DON’T ATTEMPT TO FIX the car if you encounter any problems</li>
                    <li>DON’T ATTEMPT To book this car for a location and end up in a different region</li>
                    <li>DON’T ATTEMPT To use this car for commercial purposes (Uber, Bolt)</li>
                    <li>DO Inspect the car on delivery and return.</li>
                    <li>DO contact Wopecar directly for any extensions of your booking</li>
                  </ul>
                </div>

                <div className="terms-section">
                  <h2>Terms & Conditions</h2>
                  <p>All clients should adhere to;</p>
                  <ul>
                    <li>Wopecar Terms & Conditions</li>
                    <li>The Signed Lease Agreement (T&Es) of Wopecar to enjoy this lovely car.</li>
                    <li>Soft copies of a valid driver's license and Ghana card will be inspected before booking is confirmed.</li>
                    <li>A security deposit of a day extra of the rate of the car is required for all bookings(full amount paid will be refunded when the car is returned uneventfully and has no issues).</li>
                    <li>One-day booking is UNAVAILABLE</li>
                    <li>Delivery fee of 100ghc is required for all deliveries.</li>
                    <li>All bookings must be done at least a day or two prior to the day of delivery of the car.</li>
                    <li>Pictures will be taken on the day of delivery and the car will be inspected with the pictures when the car is returned.</li>
                    <li>Any car rented should be returned at least 2 hours after the initial return time(lateness fees will be deducted from the security deposit paid initially).</li>
                    <li>Fuel should be at the same level as it was during the day of delivery(fuel money will be deducted from the security deposit paid).</li>
                  </ul>
                </div>
              </div>

              <br />
            </div>
            <Reviews />
          </div>
        )}
      </Modal>
    </div>
    <Footer/>
    </div>
  );
};

export default CarsForRent;
