// App.js
import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import HomeSection from './pages/HomeSection';
import Dashboard from './pages/Dashboard';
import AboutUs from './pages/AboutUs';
import Register from './pages/Register';
import FAQ from './pages/FAQ';



import './App.css';
import './index.css';
import Login from './pages/Login';
import OnlineStatus from './components/OnlineStatus';


const App = () => {
  return (
<div className="App">
<Router>
      <div className="app-container">
        <OnlineStatus />
        <Routes>
          <Route path="/" element={<HomeSection />} />
          <Route path="/dashboard" element={<Dashboard />} />
          <Route path="/aboutus" element={<AboutUs />} />
          <Route path="/sign-up" element={<Register />} />
          <Route path="/login" element={<Login />} />
          <Route path="faq" element={<FAQ />} />
        </Routes>
      </div>
    </Router>
</div>
  );
};

export default App;
