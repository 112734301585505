import React from 'react'
import './AboutUs.css';
import Navbar from '../components/Navbar';

function About() {
  return (
    <div className='about-section'>
        <Navbar />
          <div className="home-section">
                <div className="about-overlay"></div>
                <div className="about-left-column">
                    <h1>Who are we?</h1>
                    <p>We are a car rental company dedicated to providing exceptional service and a wide range of vehicles for rent. We understand the importance of reliability, comfort, and safety when it comes to renting a vehicle, which is why we strive to exceed our customers' expectations every time. With years of experience in the industry, we have built a reputation for reliability, professionalism, and integrity.</p>
                </div>
            </div>

            <div className="highlights-section">
                <div className="hightlights-text">
                    <h1>Our Highlights</h1>
                </div>

                <div className="highlightboxes">
                    <div className="highlightbox">
                        <h1>2023</h1>
                        <p>Commenced business</p>
                    </div>

                    <div className="highlightbox">
                        <h1>50+ </h1>
                        <p>Sucessful Transactions</p>
                    </div>

                    <div className="highlightbox">
                        <h1>Hub</h1>
                        <p>Sakumono, Tema</p>
                    </div>
                    
                </div>
          
          </div>
         
                 
          <div className="our-mission-section">

            <h1>Our Mission</h1>
            <p>Sk rentals, Our mission is to deliver unparalleled service and ensure a seamless and enjoyable experience for every customer who chooses Sk rentals. We achieve this by maintaining a diverse fleet of well-maintained vehicles, employing knowledgeable and friendly staff, and continuously improving our services to meet the evolving needs of our customers. We are committed to providing reliable transportation solutions and exceeding our customers' expectations with every rental.</p>



            <h1>Our Vision</h1>
            <p>
                Our vision is to become the premier car rental company in the industry, known for our commitment to excellence, innovation, and customer satisfaction. We aim to set the standard for exceptional service and reliability, making Daku Car Rentals the top choice for individuals and businesses alike. We strive to expand our reach and enhance our services to cater to a wider audience while maintaining the personalized touch that sets us apart.
            </p>

          </div>


          {/* <div className="our-team-section">

            <div className="team-heading">
              <p className='mini-heading'>Our Team</p>
              <h1>The people behind us</h1>
            </div>

            <div className="team-members">
              <div className="team-member">
                  <img src="" alt=""/>
                  <h1>John Doe</h1>
                  <p>CEO</p>
                </div>

                <div className="team-member">
                  <img src="" alt=""/>
                  <h1>Jane Doe</h1>
                  <p>COO</p>
                </div>

                <div className="team-member">
                  <img src="" alt=""/>
                  <h1>John Doe</h1>
                  <p>CEO</p>
                </div>

                <div className="team-member">
                  <img src="" alt=""/>
                  <h1>Jane Doe</h1>
                  <p>COO</p>
                </div>

            </div>


          </div> */}


          <div className="contact-section">
                <h1>Contact Us</h1>

                <div className='contact'>

                   <div className="contact-info-left">
                        <img loading='lazy' width="64" height="64" src="https://img.icons8.com/nolan/64/address.png" alt="address"/>
                        <p>Address: 123, Spintex, Ghana</p>
                        <p>Email: infos@skrentals.com</p>
                   </div>

                    <div className="contact-info-right">
                        <img loading='lazy' width="64" height="64" src="https://img.icons8.com/nolan/64/apple-phone.png" alt="apple-phone"/>
                        <p>Phone: +233 54 713 9631</p>
                    </div>

                </div>
          </div>

    </div>
  )
}

export default About
