import React, { useState, useEffect } from "react";
import "./Navbar.css";
import { Link } from "react-router-dom";
import { FaBars } from "react-icons/fa";


const Navbar = ({ isAuthenticated }) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [hideNavbar, setHideNavbar] = useState(false);

  const toggle = () => {
    setIsModalOpen(!isModalOpen);
  };

  const handleWhatsAppClick = () => {
    const phoneNumber = '+233547139631';
    const message = 'Hello!';
    const whatsappURL = `https://wa.me/${phoneNumber}?text=${encodeURIComponent(message)}`;
    window.open(whatsappURL, '_blank');
  };

  useEffect(() => {
    const handleScroll = () => {
      const currentScrollY = window.scrollY;
      if (currentScrollY > 0) {
        setHideNavbar(true);
      } else {
        setHideNavbar(false);
      }
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <nav className={hideNavbar ? "hide-navbar" : ""}>
      <Link to="/" className="link">
      <svg
          xmlns="http://www.w3.org/2000/svg"
          className="icon-tabler icon-tabler-brand-edge"
          width="24"
          height="24"
          color="white"
          viewBox="0 0 24 24"
          strokeWidth="2"
          stroke="currentColor"
          fill="none"
          strokeLinecap="round"
          strokeLinejoin="round"
        >

          <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
          <path d="M20.978 11.372a9 9 0 1 0 -1.593 5.773"></path>
          <path d="M20.978 11.372c.21 2.993 -5.034 2.413 -6.913 1.486c1.392 -1.6 .402 -4.038 -2.274 -3.851c-1.745 .122 -2.927 1.157 -2.784 3.202c.28 3.99 4.444 6.205 10.36 4.79"></path>
          <path d="M3.022 12.628c-.283 -4.043 8.717 -7.228 11.248 -2.688"></path>
          <path d="M12.628 20.978c-2.993 .21 -5.162 -4.725 -3.567 -9.748"></path>
        </svg>
        SKrentals
      </Link>
      <div className={`menu-items ${isModalOpen ? "active" : ""}`}>
        <Link className="link" to="/aboutus">
          About us
        </Link>
        <Link className="link" to="/FAQ">
          FAQs
        </Link>
        <button onClick={handleWhatsAppClick} className="btn modal-btn" >Chat</button>
      </div>
      <Link className="link" to="/sign-up" onClick={toggle}>
              Sign up
        </Link>
      <div className="icons">
        <div className="mobile-menu-icon">
          <FaBars onClick={toggle} />
        </div>
      </div>
      {isModalOpen && (
        <div className="modal-overlay" onClick={toggle}>
          <div className="modals" onClick={(e) => e.stopPropagation()}>
            <Link className="modal-link" to="/aboutus" onClick={toggle}>
              About us
            </Link>
            <Link className="modal-link" to="/FAQ" onClick={toggle}>
              FAQs
            </Link>
            <button onClick={handleWhatsAppClick} className="btn modal-btn" >Chat </button>
          </div>
        </div>
      )}
    </nav>
  );
};

export default Navbar;
