import React, { useState, useEffect } from 'react';
import spinnergif from '../images/ZWdx.gif';
import axios from 'axios';

const CarList = ({ handleDelete }) => {
    const [cars, setCars] = useState(null);
  
    useEffect(() => {
      const fetchCars = async () => {
        try {
          const response = await axios.get('https://skrentalsimpleserver.vercel.app/dashboard/info');
          setCars(response.data.allInfo); // Assuming that the array is under 'allInfo'
        } catch (error) {
          console.error(error);
        }
      };
  
      fetchCars();
    }, []);
  
    if (cars === null) {
      // Data is still being fetched
      return  <div className='spinnergffff'>
      <img src={spinnergif} alt="loading" />
    </div>;
    }
  
    return (
        <div className="actualhandle">
            <h1>All Cars</h1>
         <div className="cardlist">
        {cars.slice().reverse().map((car) => (
          <div key={car._id} className="car-card">
            {/* Extracting the first image URL from the array */}
            <div className='upper'>
            <div className='img1'>
            <img src={car.images[0]} alt={car.label} loading='lazy' />
            </div>
            </div>
            <div className='lower'>
            <h3>{car.label}</h3>
            <p>{car.description}</p>
            <p><strong>Location:</strong> {car.location}</p>
            <p><strong>Pickup Time:</strong> {car.pickupTime}</p>
            <p><strong>Dropoff Time:</strong> {car.dropoffTime}</p>
            <p><strong>Client Name:</strong> {car.clientName}</p>
            <p><strong>Rent Price:</strong> {car.rentPrice}</p>
            <p><strong>Paid Status:</strong> {car.paidStatus ? 'Paid' : 'Unpaid'}</p>
            </div>
            <div className='last'>
            <button  className='btn41-43 btn-42' onClick={() => handleDelete(car._id)}>Delete</button>
            </div>
          </div>
        ))}
      </div>
      </div>
    );
  };
  
  export default CarList;
  